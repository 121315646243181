@import '../../../Styles/settings';

.logo {
  margin-left: auto;
  margin-right: auto;
  width: 79px;
}
.arrow {
  width: 15px;
  margin-left: 10px;
  margin-right: 15px;
}

.flagBtn {
  position: absolute;
  right: 0;
  margin-right: 16px;
}

@media #{$sm-up} {
  .logo {
    width: 120px;
  }
}

@media #{$md-up} {
  .logo {
    width: 120px;
  }

  .flagBtn {
    margin-right: 156px;
  }
}
