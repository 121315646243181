@import '../../Styles/settings';

.epiBadgeImg {
  height: 30px;
  width: 100px;
  margin-right: 4px;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: grey;
}

.epiBadgeContainer {
  position: absolute;
  top: 112px;
  right: 0;
}

.epiBadgeContainerOnBoarding {
  position: absolute;
  top: 75px;
  right: 0;
}
      
  
.epiBadge {
  display: flex;
  align-items: center;
  margin-right: 25px;
  margin-left: 51px;
  background-color: #ff553E;
}

.links {
  color: white;
  text-decoration-line: none;
  padding: 0 20px;
  &:hover {
    background-color: #D2E116;
    color: black;
  }
}

.arrowUp {
  width: 18px;
  height: 12px;
  margin-right: 4px;
  animation: none;
  transform: rotate(180deg);
}

.arrowDown {
  width: 18px;
  height: 12px;
  margin-right: 4px;
  animation: none;
}

.dropDownContainer {
  padding: 10px 0;
  background-color: #524F4F;
  position: absolute;
  display: flex;
  flex-direction: column;
}

@media #{$sm-down} {
  .epiBadgeContainer {
    top: $mobile-header-height;
  }

  .epiBadgeContainerOnBoarding {
    top: 55px;
  }

  .epiBadge {
    margin: 0;
  }
}