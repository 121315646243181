@import '../../../Styles/settings';

.root {
  overflow: hidden;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.img {
  width: 100%;
  height: 100%;
}

@media #{$md-up} {
  .root-xs {
    width: 16px;
    height: 16px;
  }
  .root-l {
    width: 28px;
    height: 28px;
  }
}
