@import '../../../Styles/settings';

.root {
  display: flex;
  height: $mobile-header-height;
  justify-content: space-between;
  align-items: center;
  background: white;
  z-index: map-get($zIndexes, 'header');
}

.logo {
  width: 80px;
  margin-left: 15px;
}
.arrow {
  width: 15px;
  margin-left: 10px;
  margin-right: 15px;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
}

@media #{$sm-up} {
  .root {
    height: $full-width-header-height-sm-up;
    justify-content: center;
  }

  .logo {
    margin-right: 40px;
  }
}

@media #{$md-up} {
  .logo {
    width: 130px;
    margin-right: 50px;
  }
}
